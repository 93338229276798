import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';

const QuartzteqPage = () => {
  const data = useStaticQuery(graphql`
    query {
      quartzteq: file(relativePath: { eq: "quartzteq.png" }) {
        ...projectImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <h1>Quartzteq – PDA II Next Gen</h1>
          <ul>
            <li>
              Migrated a LabVIEW application to a web stack with a{' '}
              <strong>real-time view using WebSockets</strong>
            </li>
            <li>
              Designed the application to handle{' '}
              <strong>millions of data points</strong> using a downsampling
              algorithm
            </li>
            <li>
              Provided technical <strong>mentorship</strong> and guidance to
              less-experienced peers
            </li>
          </ul>
          <span className="image main">
            <Img fluid={data.quartzteq.childImageSharp.fluid} alt="quartzteq" />
          </span>
        </div>
      </div>
    </Layout>
  );
};

export default QuartzteqPage;
